import { number, plural } from '@messageformat/runtime';
import { ru } from '@messageformat/runtime/lib/plurals';

const x = (value, locale, arg) => {
                        return value[arg];
                    };
export default {
  overlay: {
    intro: {
      header: function(d) { return "Вы — тимлид\nВаша команда:\n" + d.team; },
      difficulty: function(d) { return "Сложность: " + d.difficulty; },
      teammate: function(d) { return x(d.name, "ru", (" nominative").trim()) + " — специалист в " + d.skills; }
    },
    editor: {
      nothing_done: function(d) { return "В этой задаче совсем ничего не сделано. Время поработать?"; },
      lots_of_work: function(d) { return "В текущей задаче еще полно работы"; },
      mostly_done: function(d) { return "Кажется, в этой задаче большая часть работы готова"; },
      almost_there: function(d) { return "В задачке осталось совсем немного работы"; },
      done: function(d) { return "Основной код задачи готов!"; },
      work_on_task: function(d) { return "Работать над текущей задачей (1 ч.)"; },
      work_done_1: function(d) { return "Хрустнув пальцами, вы приступаете к работе. Спустя час непрерывного стука по клавиатуре вы наконец доходите до состояния, когда можно сделать коммит"; },
      work_done_2: function(d) { return "Потянувшись в кресле, вы приступаете к работе. Спустя час злостного рефакторинга вы наконец доходите до состояния, когда можно сделать коммит"; },
      work_done_3: function(d) { return "Сделав глоток кофе, вы приступаете к работе. Спустя час распиливания сервисов вы наконец доходите до состояния, когда можно сделать коммит"; },
      work_done_4: function(d) { return "Глубоко вдохнув, вы приступаете к работе. Спустя час переписывания чужого кода вы наконец доходите до состояния, когда можно сделать коммит"; },
      passed_to_review: function(d) { return "С чувством выполненного долга вы передаете задачу в ревью"; },
      tests_done: function(d) { return "Поймав по пути пару багов, вы покрываете большую часть кода тестами. На душе становится чуть-чуть спокойнее"; },
      qa_instructions_done: function(d) { return "Попытавшись успокоить совесть, вы подробно описываете сделанные в задаче изменения и моменты, которые нужно тщательно протестировать. Это точно должно упростить и ускорить процесс QA"; },
      task_done: function(d) { return "С текущей задачкой покончено!"; },
      action: {
        pass_to_review: function(d) { return "Передать задачу в ревью"; },
        write_tests: function(d) { return "Написать тесты (1 ч.)"; },
        write_qa_instructions: function(d) { return "Написать подробные инструкции для QA (1 ч.)"; },
        open_wrike_to_take_new_task: function(d) { return "Открыть Wrike и взять следующую"; },
        do_something_else: function(d) { return "Заняться чем-нибудь другим"; }
      }
    },
    code_review: {
      look_at_code: {
        bad: function(d) { return "Вы открываете задачу " + x(d.name, "ru", (" genitive").trim()) + " в вечно тормозящем GitPub'e. Код выглядит откровенно плохо"; },
        not_too_bad: function(d) { return "Вы открываете задачу " + x(d.name, "ru", (" genitive").trim()) + " в вечно тормозящем GitPub'e. Код смотрится довольно небрежным"; },
        ok: function(d) { return "Вы открываете задачу " + x(d.name, "ru", (" genitive").trim()) + " в вечно тормозящем GitPub'e. На первый взгляд, код неплох"; }
      },
      passed_to_qa_without_looking: function(d) { return "Недолго думая, вы ставите 'Review OK' и передаете задачу в QA. Своей команде вы все-таки доверяете"; },
      found_bugs: function(d) { return "Тщательно прошерстив код, вы обнаруживаете в нем несколько багов и спорных моментов. Задача возвращается обратно к разработчику"; },
      couldnt_find_bugs: function(d) { return "Долго щурясь и всматриваясь в код, вы все-же не находите к чему придраться. Задача отправляется в тестирование"; },
      action: {
        pass_to_qa_without_looking: function(d) { return "Передать в тестирование не глядя"; },
        look_at_code: function(d) { return "Тщательно просмотреть код (1 ч.)"; },
        do_something_else: function(d) { return "Подумать еще"; }
      }
    },
    standup: {
      you: {
        name: function(d) { return "Вы"; },
        working_on: function(d) { return "Пока что работаю над " + d.task; },
        will_take_next_task: function(d) { return "Беру следующую задачу в работу"; }
      },
      teammate: {
        worked_on: {
          v1: function(d) { return "Работал над"; },
          v2: function(d) { return "Старался над"; },
          v3: function(d) { return "Залипал в"; },
          v4: function(d) { return "Трудился над"; },
          v5: function(d) { return "Тупил над"; },
          v6: function(d) { return "Врубался в"; },
          v7: function(d) { return "Втыкал в"; },
          base: function(d) { return d.worked_on + " " + d.task; },
          stuck: function(d) { return d.worked_on + " " + d.task + ", немного застрял, пытаюсь разобраться"; },
          finishing_soon: function(d) { return d.worked_on + " " + d.task + ", надеюсь, сегодня закончу"; }
        },
        finished_all_tasks: {
          v1: function(d) { return "Закончил все свои задачи, жду следующей"; },
          v2: function(d) { return "Задачки кончились, что взять следующим?"; },
          v3: function(d) { return "Предыдущие задачки уехали, готов взять еще"; },
          v4: function(d) { return "Прошлая задачка готова, какую брать дальше?"; },
          v5: function(d) { return "Доделал всё, над чем работал, буду брать новую задачку"; }
        }
      },
      action: {
        "continue": function(d) { return "Продолжить"; },
        suggest_task: function(d) { return "Предложить взять " + d.task + ", оценка: " + d.estimated_time; },
        continue_no_free_tasks: function(d) { return "Нет свободных задач, продолжить"; },
        assign_to_yourself: function(d) { return "Взять на себя " + d.task + ", оценка: " + d.estimated_time; }
      },
      finished: function(d) { return "Стендап закончен, рабочий день начался!"; }
    },
    calendar: {
      lunch: {
        message: function(d) { return "Сегодня в местной столовой, как всегда, людно. Отстояв в очереди, вы покупаете " + d.food + " и с удовольствием обедаете [+Здоровье]"; },
        food: {
          v1: function(d) { return "макарошки с курицей"; },
          v2: function(d) { return "пюрешку с гуляшем"; },
          v3: function(d) { return "супчик"; },
          v4: function(d) { return "котлетки"; },
          v5: function(d) { return "салатик"; },
          v6: function(d) { return "рыбку"; },
          v7: function(d) { return "кусочек пиццы"; }
        }
      },
      standup: {
        info: function(d) { return "На ежедневном стендапе все члены команды делятся краткой информацией о проделанной за предыдущий день работе и текущих блокерах"; }
      },
      one_on_one: {
        info: function(d) { return "На еженедельных One on One встречах члены команды встречаются с их прямым менеджером и обсуждают рабочие и личные вопросы в свободной форме. Это помогает увеличить доверие между членами команды и выявить потенциальные проблемы заранее"; },
        message: function(d) { return "One on One прошел продуктивно. " + x(d.name, "ru", (" nominative").trim()) + " поделился своими переживаниями по поводу дедлайна и успехами в работе. Вы поделились кусочком тимлидской мудрости, и " + x(d.name, "ru", (" nominative").trim()) + " явно остался доволен [+Навык]"; }
      },
      knowledge_sharing: {
        info: function(d) { return "Knowledge Sharing — еженедельное событие, на котором один из разработчиков освещает потенциально полезную коллегам тему. Тема сегодняшней встречи — " + d.topic; },
        message: function(d) { return "Sharing сегодня был максимально интересный. Вы буквально прозрели на тему " + d.topic + " и чувствуете, будто теперь можете вообще всё [+Производительность]"; }
      },
      interview: {
        info: function(d) { return "Одна из Ваших обязанностей — участвовать в собеседованиях потенциальных кандидатов, в том числе в другие команды"; },
        message: function(d) { return "Собеседование, как всегда, было довольно выматывающим. Но сегодняшний кандидат, " + d.candidate_name + ", довольно интересный"; }
      },
      lead_meeting: {
        info: function(d) { return "Еженедельный синк тимлидов служит чем-то вроде форума для быстрой коммуникации среди команд. На нем всегда можно узнать свежие новости о работе других коллег или задать вопрос о пересекающейся функциональности"; },
        message: function(d) { return "На сегодняшнем синке не было ничего особо интересного"; }
      },
      already_started: function(d) { return "Эта встреча уже началась, вы опоздали!"; },
      already_finished: function(d) { return "Это событие уже закончилось"; },
      not_started_yet: function(d) { return "Это событие еще не началось, к нему можно будет присоединиться позже!"; },
      action: {
        lunch: function(d) { return "Идти на обед (1 ч.)"; },
        standup: function(d) { return "Подключиться к стендапу (1 ч.)"; },
        one_on_one: function(d) { return "Подключиться к встрече (1 ч.)"; },
        knowledge_sharing: function(d) { return "Присоединиться и послушать (1 ч.)"; },
        interview: function(d) { return "Подключиться к звонку (2 ч.)"; },
        lead_meeting: function(d) { return "Подключиться к встрече (1 ч.)"; },
        do_something_else: function(d) { return "Подумать еще"; }
      }
    },
    teammate: {
      action: {
        help: function(d) { return "Помочь " + x(d.name, "ru", (" dative").trim()) + " с задачей (1 ч.)"; }
      },
      helped: function(d) { return "Свежим взглядом пробежавшись по задаче " + x(d.name, "ru", (" genitive").trim()) + ", вы довольно быстро обнаруживаете проблему и мудрым советом помогаете ему продолжить работу [+Навык]"; },
      nothing_to_talk_about: function(d) { return "Не приходит в голову, о чем сейчас можно было бы поговорить"; },
      cant_talk_after_work: function(d) { return "Время уже не рабочее, и " + x(d.name, "ru", (" nominative").trim()) + " ушел в оффлайн, лучше написать ему позже"; }
    },
    task: {
      status: {
        not_started: function(d) { return "Над этой задачей еще никто не начинал работу"; },
        in_dev: {
          you: function(d) { return "Сейчас назначена на вас!"; },
          teammate: function(d) { return "Над этой задачей сейчас работает " + x(d.name, "ru", (" nominative").trim()); }
        },
        in_review: {
          yours: function(d) { return "Вы передали эту задачу в ревью другим тимлидам. Обычно это занимает порядка одного рабочего дня"; },
          teammate: function(d) { return "Задача " + x(d.name, "ru", (" genitive").trim()) + " готова и ждет от вас ревью"; }
        },
        in_testing: function(d) { return "Эта задача передана в тестирование. Если все идет нормально, процесс занимает порядка одного рабочего дня, но при обнаружении серьезных дефектов задача всегда может быть возвращена разработчику"; },
        done: function(d) { return "Эта задача уже на проде. Мы такие молодцы!"; }
      },
      action: {
        go_to_editor: function(d) { return "Перейти в редактор CodeJunkie"; },
        go_to_review: function(d) { return "Сделать ревью"; },
        assign_to_you: function(d) { return "Взять в разработку"; }
      }
    },
    inbox: {
      review_info: function(d) { return "После выполнения задачи разработчиком необходимо провести ревью кода. Обычно эта задача ложится на тимлида (т.е. вас)"; },
      returned_to_dev: function(d) { return "Судя по всему, команда QA обнаружила в этой задаче серьезные баги и вернула ее обратно разработчику"; },
      production_bug: function(d) { return "Тикет от саппорта. В одной из наших задач, которая уже уехала на прод, обнаружились серьезные баги. Их нужно исправить за отведенное время (по нашей договоренности с клиентами). Багов осталось: " + d.num_bugs; },
      production_bug_fixed: function(d) { return "Похоже, что все проблемы в тикете исправлены, клиенты подтвердили отсутствие дефектов, можно расслабиться"; },
      action: {
        review: function(d) { return "Сделать ревью"; },
        mark_as_read: function(d) { return "Пометить прочитанным"; },
        fix_bugs: function(d) { return "Работать над багами (1 ч.)"; },
        do_something_else: function(d) { return "Подумать еще"; }
      }
    },
    browser: {
      jabr: function(d) { return "Жабр — один из самых известных ресурсов с постоянно пополняющимся архивом технических статей"; },
      jabr_read: function(d) { return "Пролистав пару технических статей и с десяток холиваров в комментариях, вы буквально чувствуете, как растет ваш мозг [+Производительность]"; },
      you_cube: function(d) { return "Интересно, что нового на вашем любимом сайте с видосиками?"; },
      you_cube_watched: function(d) { return "Посмотрев " + d.video + ", вы чувствуете себя совершенно свободным от мирских забот [-Выгорание]"; },
      video: {
        v1: function(d) { return "смешные моменты из Офиса"; },
        v2: function(d) { return "пару видосов Лапенко"; },
        v3: function(d) { return "интервью Дудя"; },
        v4: function(d) { return "обзор Вилсакома"; },
        v5: function(d) { return "летсплей Куплинова"; },
        v6: function(d) { return "серию Зашкварных Историй"; },
        v7: function(d) { return "видосик Сыендука"; },
        v8: function(d) { return "эпизод TiX"; },
        v9: function(d) { return "видео об очередном изобретении KREOSAN'а"; }
      },
      action: {
        read_jabr: function(d) { return "Читать Жабр (1 ч.)"; },
        you_cube: function(d) { return "Смотреть Юкуб (1 ч.)"; }
      }
    },
    computer: {
      finish_work_for_today: function(d) { return "Завершить работу на сегодня"; }
    },
    weekend: {
      day: {
        mon: function(d) { return "Наступил понедельник"; },
        tue: function(d) { return "Наступил вторник"; },
        wed: function(d) { return "Наступила среда"; },
        thu: function(d) { return "Наступил четверг"; },
        fri: function(d) { return "Наступила пятница"; },
        sat: function(d) { return "Наступила суббота"; },
        sun: function(d) { return "Наступило воскресенье"; }
      },
      message: function(d) { return d.day_started + ". Сегодня у вас заслуженный выходной. Вы можете продолжить работать, но достоверно известно, что в выходные нужно отдыхать"; },
      action: {
        rest: function(d) { return "Отдыхать до понедельника"; },
        do_something_else: function(d) { return "Заняться чем-то еще"; }
      }
    },
    game_over: {
      reason: {
        victory: function(d) { return "Задачка за задачкой уходят в прод, и вы едва успеваете заметить, как закончился беклог. Вы сделали это! Дедлайн побежден!"; },
        deadline_failed: function(d) { return "День за днем, неделя за неделей дедлайн подбирался всё ближе. Вы уже давно чувствовали, что это случится, но в каком-то рьяном ступоре продолжали работать, возможно надеясь, что оно как-то само собой рассосется. Не рассосалось. Дедлайн провален. Клиент потерян. Крышка."; },
        fired: function(d) { return "Ваш компьютер неожиданно блокируется. В сотый раз проклиная производителей PearNote, вы пытаетесь вернуться к работе, введя пароль. Ноутбук все продолжает отрицать легитимность его хозяина, издавая противный звук при каждой неудачной попытке входа. «Видимо, ему совсем кранты», — думаете вы, доставая телефон, чтобы написать инженерам хелпдеска. На почту падает новое письмо. «Уволен одним днем за неисполнение служебных обязанностей». Что?!"; },
        bad_health: function(d) { return "В последние дни вы совсем погрузились в работу, игнорируя приемы пищи и сон. За всем этим вы и не заметили, как кожа приобрела бледный оттенок, а под глазами появились синие мешки. Ваши родственники находят вас в состоянии обморока прямо у компьютера. Вы отправляетесь в больницу, о работе можно забыть на следующие пару недель, не говоря уже о приближающемся дедлайне."; },
        burnout: function(d) { return "Вы уже и забыли, что такое отдых. Закрывая задачу за задачей, вы не замечаете, как все свободное время превращается в рабочее. В какой-то момент внутри вас что-то щелкает. Зачем вам вообще это нужно? К чему все эти старания? Куда я двигаюсь? «А пропади оно все пропадом! К черту работу, к черту дедлайн, мне уже все равно!», — думаете вы и покупаете авиабилет в Саратов к родителям. Выгорание — это вам не шутки."; }
      },
      tip: {
        bad_health: function(d) { return "Не забывайте есть и достаточно спать"; },
        burnout: function(d) { return "Меньше работайте в нерабочее время, в том числе в выходные!"; },
        fired: function(d) { return "Следите за посещением обязательным рабочих встреч и багами на продакшне (в вашем инбоксе)"; }
      },
      message: {
        header: function(d) { return "Игра окончена"; },
        difficulty: function(d) { return "Сложность: " + d.difficulty; },
        tip: function(d) { return "Подсказка: " + d.tip; }
      },
      action: {
        restart: function(d) { return "Начните заново"; },
        learn_more: function(d) { return "Или узнайте, как работают инжиниринговые команды в Wrike"; }
      }
    }
  },
  top_bar: {
    day: {
      mon: function(d) { return "Понедельник"; },
      tue: function(d) { return "Вторник"; },
      wed: function(d) { return "Среда"; },
      thu: function(d) { return "Четверг"; },
      fri: function(d) { return "Пятница"; },
      sat: function(d) { return "Суббота"; },
      sun: function(d) { return "Воскресенье"; }
    },
    today: function(d) { return "Сентябрь " + plural(d.day, 0, ru, { other: number("ru", d.day, 0) }, 1) + ", " + d.day_of_week; },
    finish_work_tip: function(d) { return "Завершить работу на сегодня можно здесь"; }
  },
  assigned_to_you: function(d) { return "Вы"; },
  difficulty: {
    junior: function(d) { return "Джуниор"; },
    middle: function(d) { return "Мидл"; },
    senior: function(d) { return "Сеньор"; },
    teamlead: function(d) { return "Тимлид"; }
  },
  deadline_stats: {
    days_until: function(d) { return "До дедлайна " + plural(d.days, 0, ru, { one: number("ru", d.days, 0) + " день", few: number("ru", d.days, 0) + " дня", many: number("ru", d.days, 0) + " дней", other: number("ru", d.days, 0) + " дня" }); },
    tasks_remaining: function(d) { return plural(d.tasks, 0, ru, { one: "Осталось", few: "Остались", many: "Осталось", other: "Осталось" }) + " " + plural(d.tasks, 0, ru, { other: number("ru", d.tasks, 0) }) + " " + plural(d.tasks, 0, ru, { one: "задача", few: "задачи", many: "задач", other: "задачи" }) + " (из " + d.total + ")"; },
    backlog: function(d) { return "В беклоге " + plural(d.tasks, 0, ru, { other: number("ru", d.tasks, 0) }) + " " + plural(d.tasks, 0, ru, { one: "задача", few: "задачи", many: "задач", other: "задачи" }); }
  },
  status: {
    health: {
      perfect: function(d) { return "Отличное"; },
      good: function(d) { return "Хорошее"; },
      fine: function(d) { return "Неважное"; },
      bad: function(d) { return "Плохое"; },
      terrible: function(d) { return "Отвратительное"; },
      zero: function(d) { return "Обморок"; }
    },
    company: {
      perfect: function(d) { return "Отличное"; },
      good: function(d) { return "HR'ы начинают что-то подозревать"; },
      fine: function(d) { return "С вашим менеджером ведутся разговоры"; },
      bad: function(d) { return "Оформляется Personal Improvement Plan"; },
      terrible: function(d) { return "Подписываются бумаги об увольнении"; },
      zero: function(d) { return "Уволен"; }
    },
    burnout: {
      perfect: function(d) { return "Отличное"; },
      good: function(d) { return "Легкое раздражение от работы"; },
      fine: function(d) { return "Не хочется вставать по утрам"; },
      bad: function(d) { return "Работа вызывает отвращение"; },
      terrible: function(d) { return "Близко к выгоранию"; },
      zero: function(d) { return "Выгорел"; }
    },
    label: {
      health: function(d) { return "Физическое здоровье"; },
      burnout: function(d) { return "Ментальное здоровье"; },
      company: function(d) { return "Положение в компании"; },
      performance: function(d) { return "Производительность"; }
    }
  },
  chat: {
    action: {
      message_teammate: function(d) { return "Написать " + x(d.name, "ru", (" dative").trim()); }
    },
    greetings: {
      v1: function(d) { return "Привет"; },
      v2: function(d) { return "Прив"; },
      v3: function(d) { return "Хай"; },
      v4: function(d) { return "Даров"; },
      v5: function(d) { return "Здоровеньки булы"; }
    },
    thanks: {
      v1: function(d) { return "Спасибо"; },
      v2: function(d) { return "Спасибо!"; },
      v3: function(d) { return "Спасибо!!"; }
    },
    figured_out: {
      v1: function(d) { return "Разобрался"; },
      v2: function(d) { return "Всё, сам догнал, сорян"; },
      v3: function(d) { return "Ух, все, допер сам"; },
      v4: function(d) { return "Ага, понял сам в чем проблема, не отвлекаю"; },
      v5: function(d) { return "Осилил сам вроде, если что ещё напишу"; }
    },
    help: {
      v1: function(d) { return "Я что-то залип с " + d.task_name + ", поможешь?"; },
      v2: function(d) { return "Не могу разобраться с " + d.task_name + " " + plural(d.sadness, 0, ru, { "0": "(", "1": "((", other: "(((" }) + ". Можешь помочь?"; },
      v3: function(d) { return "Что-то я совсем застрял на " + d.task_name + ". Подсобишь?"; },
      v4: function(d) { return d.task_name + " оказалась мне не по силам, поможешь?"; },
      v5: function(d) { return "Можем созвониться по " + d.task_name + "? Не могу разобрать одну проблему."; }
    }
  },
  teammate: {
    name_nom_gen_dat: {
      v1: function(d) { return "Иван|Ивана|Ивану"; },
      v2: function(d) { return "Вася|Васи|Васе"; },
      v3: function(d) { return "Дима|Димы|Диме"; },
      v4: function(d) { return "Коля|Коли|Коле"; },
      v5: function(d) { return "Вадик|Вадика|Вадику"; }
    }
  },
  candidate: {
    first_names: function(d) { return "Авдей|Авксентий|Агафон|Акакий|Александр|Владлен|Влас|Всеволод|Вячеслав|Макар|Максим|Марк|Матвей|Тарас|Тимофей|Тимур|Святослав|Севастьян|Семён|Серафим|Сергей|Павел|Паисий|Панкратий|Пантелеймон|Парфений"; },
    last_names: function(d) { return "Сафонов|Капустин|Кириллов|Моисеев|Елисеев|Кошелев|Костин|Горбачёв|Орехов|Ефремов|Исаев|Евдокимов|Калашников|Кабанов|Носков|Юдин|Кулагин|Лапин|Прохоров|Нестеров|Харитонов|Агафонов|Муравьёв|Ларионов|Федосеев|Зимин|Пахомов"; }
  },
  app: {
    browser: {
      name: function(d) { return "Iron Browser"; }
    },
    chat: {
      name: function(d) { return "Limp Messenger"; }
    },
    calendar: {
      name: function(d) { return "Clndr"; }
    },
    editor: {
      name: function(d) { return "Code Junkie"; }
    },
    wrike: {
      name: function(d) { return "Wrike"; }
    },
    computer: {
      name: function(d) { return "PearNote"; }
    }
  },
  website: {
    blog: function(d) { return "Jabr"; },
    videos: function(d) { return "YouCube"; }
  },
  feedback_note: function(d) { return "Есть фидбек или идеи для улучшения? Пишите на techclub@team.wrike.com"; }
}