import { number, plural } from '@messageformat/runtime';
import { en } from '@messageformat/runtime/lib/plurals';

const x = (value, locale, arg) => {
                        return value[arg];
                    };
export default {
  overlay: {
    intro: {
      header: function(d) { return "You're the team lead!\nYour team:\n" + d.team; },
      difficulty: function(d) { return "Difficulty: " + d.difficulty; },
      teammate: function(d) { return x(d.name, "en", (" nominative").trim()) + " specializes in " + d.skills; }
    },
    editor: {
      nothing_done: function(d) { return "Nothing has been done in this task so far. Ready to work?"; },
      lots_of_work: function(d) { return "The current task still has a lot of work to be done"; },
      mostly_done: function(d) { return "This task is close to being finished"; },
      almost_there: function(d) { return "This task has a little work to be done"; },
      done: function(d) { return "The main code for the task is ready!"; },
      work_on_task: function(d) { return "Work on the current task (1 hr)"; },
      work_done_1: function(d) { return "With a crack of your knuckles, you get to work. After an hour of continuous tapping on your keyboard, you finally reach the point where you can commit"; },
      work_done_2: function(d) { return "Stretching in your chair, you get to work. After an hour of hard refactoring, you finally reach a point where you can commit"; },
      work_done_3: function(d) { return "Taking a sip of coffee, you get to work. After an hour of creating services, you finally reach a point where you can commit"; },
      work_done_4: function(d) { return "Taking a deep breath, you get to work. After an hour of rewriting code, you finally reach a point where you can commit"; },
      passed_to_review: function(d) { return "With a sense of accomplishment, you submit the task for review"; },
      tests_done: function(d) { return "Finding a couple of bugs along the way, you test most of the code. You feel a little calmer"; },
      qa_instructions_done: function(d) { return "You describe the detailed changes made in the task and the areas that need to be thoroughly tested. This should simplify and accelerate the QA process"; },
      task_done: function(d) { return "You've finished your task!"; },
      action: {
        pass_to_review: function(d) { return "Submit task for review"; },
        write_tests: function(d) { return "Write tests (1 hr)"; },
        write_qa_instructions: function(d) { return "Write detailed instructions for QA (1 hr)"; },
        open_wrike_to_take_new_task: function(d) { return "Open Wrike and accept the next one"; },
        do_something_else: function(d) { return "Do something else"; }
      }
    },
    code_review: {
      look_at_code: {
        bad: function(d) { return "You open " + x(d.name, "en", (" genitive").trim()) + "'s task with the ever-sluggish GitPub. Honestly, the code looks awful"; },
        not_too_bad: function(d) { return "You open " + x(d.name, "en", (" genitive").trim()) + "'s task with the slowly working GitPub. The code looks quite messy"; },
        ok: function(d) { return "You open " + x(d.name, "en", (" genitive").trim()) + "'s task with the ever-sluggish GitPub. At first glance, the code looks good"; }
      },
      passed_to_qa_without_looking: function(d) { return "You trust your team. Without thinking much, you approve the code and transfer the task to QA."; },
      found_bugs: function(d) { return "Thoroughly checking the code, you find several bugs and issues. The task is returned to the developer"; },
      couldnt_find_bugs: function(d) { return "After checking the code for a long time, you didn't find any issues. The task is sent for testing"; },
      action: {
        pass_to_qa_without_looking: function(d) { return "Submit for testing without checking"; },
        look_at_code: function(d) { return "Carefully check the code (1 hr)"; },
        do_something_else: function(d) { return "Do something else"; }
      }
    },
    standup: {
      you: {
        name: function(d) { return "You"; },
        working_on: function(d) { return "Right now, I'm working on " + d.task; },
        will_take_next_task: function(d) { return "I accept the next task"; }
      },
      teammate: {
        worked_on: {
          v1: function(d) { return "I've worked on"; },
          v2: function(d) { return "I'm still working on"; },
          v3: function(d) { return "I'm stuck on"; },
          v4: function(d) { return "I'm trying to figure out"; },
          v5: function(d) { return "I've been slowly working on"; },
          v6: function(d) { return "I'm still working on"; },
          v7: function(d) { return "I'm trying to understand"; },
          base: function(d) { return d.worked_on + " " + d.task; },
          stuck: function(d) { return "I'm a little stuck on " + d.worked_on + " " + d.task + ". I'm trying to figure it out"; },
          finishing_soon: function(d) { return d.worked_on + " " + d.task + ", I hope to finish it today"; }
        },
        finished_all_tasks: {
          v1: function(d) { return "I've completed all my tasks, and I'm ready for the next one"; },
          v2: function(d) { return "All my tasks are done. What should I do next?"; },
          v3: function(d) { return "All tasks are done. I'm ready to accept more"; },
          v4: function(d) { return "I've finished all my tasks. What should I work on next?"; },
          v5: function(d) { return "I've completed everything I was working on, and I'm ready for a new task"; }
        }
      },
      action: {
        "continue": function(d) { return "Continue"; },
        suggest_task: function(d) { return "Suggest accepting " + d.task + ", estimate: " + d.estimated_time; },
        continue_no_free_tasks: function(d) { return "No tasks to be done, continue"; },
        assign_to_yourself: function(d) { return "Accept " + d.task + ", estimate: " + d.estimated_time; }
      },
      finished: function(d) { return "The standup is over, a new working day has begun!"; }
    },
    calendar: {
      lunch: {
        message: function(d) { return "The company canteen was more crowded today than usual. After standing in line, you bought " + d.food + " and enjoyed your relaxing meal. [+Health]"; },
        food: {
          v1: function(d) { return "pasta with chicken"; },
          v2: function(d) { return "a burger with fries"; },
          v3: function(d) { return "a soup"; },
          v4: function(d) { return "pork chops with roasted potatoes"; },
          v5: function(d) { return "a salad"; },
          v6: function(d) { return "a chicken sandwich"; },
          v7: function(d) { return "a few slices of pizza"; }
        }
      },
      standup: {
        info: function(d) { return "At the daily standup, all team members share some info about the work they did the day before and any roadblocks they may face"; }
      },
      one_on_one: {
        info: function(d) { return "At weekly One on One meetings, team members meet their line manager and freely discuss both work and personal issues. This helps them build trust and proactively identify potential problems"; },
        message: function(d) { return "The One on One meeting went well. " + x(d.name, "en", (" nominative").trim()) + " shared their feelings about deadlines and work success. You shared a piece of Team Lead wisdom, and " + x(d.name, "en", (" nominative").trim()) + " was clearly pleased with it [+Skill]"; }
      },
      knowledge_sharing: {
        info: function(d) { return "Knowledge Sharing is a weekly event where a developer covers a topic that may be useful for their coworkers. Today's meeting is about " + d.topic; },
        message: function(d) { return "The Knowledge Sharing meeting was very interesting today. You learn about on " + d.topic + " and feel like you can do anything now [+Performance]"; }
      },
      interview: {
        info: function(d) { return "It's your job to participate in interviews with potential candidates, including candidates for other teams."; },
        message: function(d) { return "That went well. Today's candidate, " + d.candidate_name + ", was pretty interesting."; }
      },
      lead_meeting: {
        info: function(d) { return "The Team Lead Sync is a weekly meeting where you can quickly communicate with other team leads. You can hear the latest news about what your coworkers are working on and ask questions about cross-functional work"; },
        message: function(d) { return "Nothing particularly interesting happened in this week's sync"; }
      },
      already_started: function(d) { return "This meeting started already, you're late!"; },
      already_finished: function(d) { return "This event has already ended"; },
      not_started_yet: function(d) { return "This event hasn't started yet, you can join it later"; },
      action: {
        lunch: function(d) { return "Take a lunch break (1 hr)"; },
        standup: function(d) { return "Join the standup (1 hr)"; },
        one_on_one: function(d) { return "Join the meeting (1 hr)"; },
        knowledge_sharing: function(d) { return "Join and listen (1 hr)"; },
        interview: function(d) { return "Join the call (2 hrs)"; },
        lead_meeting: function(d) { return "Join the meeting (1 hr)"; },
        do_something_else: function(d) { return "Do something else"; }
      }
    },
    teammate: {
      action: {
        help: function(d) { return "Help " + x(d.name, "en", (" dative").trim()) + " with the task (1 hr)"; }
      },
      helped: function(d) { return "Taking a fresh look at " + x(d.name, "en", (" genitive").trim()) + "'s task, you quickly find the problem and give them some advice [+Skill]"; },
      nothing_to_talk_about: function(d) { return "I don't have anything to talk to them about right now"; },
      cant_talk_after_work: function(d) { return "It's too late in the day, " + x(d.name, "en", (" nominative").trim()) + " went offline. Try talking to them later."; }
    },
    task: {
      status: {
        not_started: function(d) { return "No one has started working on this task"; },
        in_dev: {
          you: function(d) { return "Assigned to you!"; },
          teammate: function(d) { return x(d.name, "en", (" nominative").trim()) + " is currently working on this task"; }
        },
        in_review: {
          yours: function(d) { return "You submitted this task to other team leads for review. This usually takes about 1 business day."; },
          teammate: function(d) { return x(d.name, "en", (" genitive").trim()) + "'s task is completed and waiting for your review"; }
        },
        in_testing: function(d) { return "This task was submitted for testing. If everything goes well, this process takes about 1 business day. But, if serious issues are found, the task can always be returned to the developer."; },
        done: function(d) { return "This task is in production now. Great job!"; }
      },
      action: {
        go_to_editor: function(d) { return "Go to CodeJunkie editor"; },
        go_to_review: function(d) { return "Review code"; },
        assign_to_you: function(d) { return "Accept for development"; }
      }
    },
    inbox: {
      review_info: function(d) { return "Once a developer completes a task, their code needs to be reviewed by their team lead (you)"; },
      returned_to_dev: function(d) { return "It seems that the QA team has found serious bugs in this task and bounced it back to the developer"; },
      production_bug: function(d) { return "Support ticket. Apparently, some serious bugs have been found in one of our tasks that were transferred to production. They need to be fixed within a limited time period (as agreed with clients). Bugs left: " + d.num_bugs; },
      production_bug_fixed: function(d) { return "It looks like all the problems reported in the ticket have been fixed and the clients have let you know that there aren't any more issues. You can relax"; },
      action: {
        review: function(d) { return "Review code"; },
        mark_as_read: function(d) { return "Mark as read"; },
        fix_bugs: function(d) { return "Work on bugs (1 hr)"; },
        do_something_else: function(d) { return "Do something else"; }
      }
    },
    browser: {
      jabr: function(d) { return "DevTech Mag is a popular resource with tons of tech articles"; },
      jabr_read: function(d) { return "After scrolling through a couple of tech articles and reading a dozen holy wars in the comments, you feel increasingly big-brained [+Performance]"; },
      you_cube: function(d) { return "Want to see what's new on your favorite video website?"; },
      you_cube_watched: function(d) { return "After watching " + d.video + ", you feel a lot more relaxed [-Burnout]"; },
      video: {
        v1: function(d) { return "funny clips from The Office TV show"; },
        v2: function(d) { return "a couple of cat videos"; },
        v3: function(d) { return "an interview by Philip DeFranco"; },
        v4: function(d) { return "a review by Marques Brownlee"; },
        v5: function(d) { return "a let's play by Markiplier"; },
        v6: function(d) { return "some WWE wrestling videos"; },
        v7: function(d) { return "a video by PewDiePie"; },
        v8: function(d) { return "an episode of The Mandolorian"; },
        v9: function(d) { return "a new invention video by TechZone"; }
      },
      action: {
        read_jabr: function(d) { return "Read DevTech Mag (1 hr)"; },
        you_cube: function(d) { return "Watch YouCube (1 hr)"; }
      }
    },
    computer: {
      finish_work_for_today: function(d) { return "Finish work (for today)"; }
    },
    weekend: {
      day: {
        mon: function(d) { return "It's Monday"; },
        tue: function(d) { return "It's Tuesday"; },
        wed: function(d) { return "It's Wednesday"; },
        thu: function(d) { return "It's Thursday"; },
        fri: function(d) { return "It's Friday"; },
        sat: function(d) { return "It's Saturday"; },
        sun: function(d) { return "It's Sunday"; }
      },
      message: function(d) { return d.day_started + ". It's the weekend, and you should enjoy your well-deserved days off. You can continue working, though, if you want."; },
      action: {
        rest: function(d) { return "Relax until Monday"; },
        do_something_else: function(d) { return "Do something else"; }
      }
    },
    game_over: {
      reason: {
        victory: function(d) { return "All tasks went to production and you've just noticed that the backlog is empty. You did it! You met the deadline!"; },
        deadline_failed: function(d) { return "The deadline kept creeping closer, day after day, week after week. \n\nYou had a feeling this would happen, but you continued to work in some sort of zealous stupor, hoping that things would sort themselves out. But, they didn't. \n\nYou missed the deadline. The customer is lost. The end."; },
        fired: function(d) { return "Your computer unexpectedly locked itself. Cursing the PearNote developers for the millionth time, you entered your password to log back in. \n\nIt didn't work. The laptop angrily beeps at you. You try again. No luck.\n\n\"Huh, must be broken,\" you think while taking out your phone to contact IT for help. You see a new email with the subject line \"Fired for poor performance.\" What?!"; },
        bad_health: function(d) { return "Recently, you've been really absorbed in your work, even ignoring meals and sleep. You were so focuesed that you didn't even notice your skin becoming pale and the dark circles under your eyes.\n\nYour family members find you passed out at the computer. You're brought to the hospital, and you can forget about working for the next couple of weeks, not to mention the approaching deadline."; },
        burnout: function(d) { return "You've forgotten what it feels like to relax. Completing task after task, you didn't notice that all your free time turned into work time. Suddenly something clicked in your head. Why do you need this all? Why all this effort? Where am I going? \n\n“Screw it all! Screw the work, Screw the deadline, I don't care anymore!” you yell while buying a plane ticket back to your hometown. Burnout is no joke, you know."; }
      },
      tip: {
        bad_health: function(d) { return "Remember to get enough food and rest"; },
        burnout: function(d) { return "Work less after hours, including weekends!"; },
        fired: function(d) { return "Remember to attend important business meetings and track bugs in your inbox"; }
      },
      message: {
        header: function(d) { return "Game over"; },
        difficulty: function(d) { return "Difficulty: " + d.difficulty; },
        tip: function(d) { return "Hint: " + d.tip; }
      },
      action: {
        restart: function(d) { return "Restart the game"; },
        learn_more: function(d) { return "Or learn about engineering teams at Wrike"; }
      }
    }
  },
  top_bar: {
    day: {
      mon: function(d) { return "Monday"; },
      tue: function(d) { return "Tuesday"; },
      wed: function(d) { return "Wednesday"; },
      thu: function(d) { return "Thursday"; },
      fri: function(d) { return "Friday"; },
      sat: function(d) { return "Saturday"; },
      sun: function(d) { return "Sunday"; }
    },
    today: function(d) { return "September " + plural(d.day, 0, en, { other: number("en", d.day, 0) }, 1) + ", " + d.day_of_week; },
    finish_work_tip: function(d) { return "You can finish your day's work here"; }
  },
  assigned_to_you: function(d) { return "You"; },
  difficulty: {
    junior: function(d) { return "Junior"; },
    middle: function(d) { return "Mid-level"; },
    senior: function(d) { return "Senior"; },
    teamlead: function(d) { return "Team lead"; }
  },
  deadline_stats: {
    days_until: function(d) { return "The deadline is in " + plural(d.days, 0, en, { one: number("en", d.days, 0) + " day", other: number("en", d.days, 0) + " days" }); },
    tasks_remaining: function(d) { return plural(d.tasks, 0, en, { one: "", other: "" }) + " " + plural(d.tasks, 0, en, { other: number("en", d.tasks, 0) }) + " " + plural(d.tasks, 0, en, { one: "task left", other: "tasks left" }) + " (of " + d.total + ")"; },
    backlog: function(d) { return plural(d.tasks, 0, en, { other: number("en", d.tasks, 0) }) + " " + plural(d.tasks, 0, en, { one: "task backlogged", other: "tasks backlogged" }); }
  },
  status: {
    health: {
      perfect: function(d) { return "Perfect"; },
      good: function(d) { return "Good"; },
      fine: function(d) { return "Weak"; },
      bad: function(d) { return "Poor"; },
      terrible: function(d) { return "Sick"; },
      zero: function(d) { return "Collapsing"; }
    },
    company: {
      perfect: function(d) { return "Perfect"; },
      good: function(d) { return "HR is starting to ask questions"; },
      fine: function(d) { return "Conversations are underway with your manager"; },
      bad: function(d) { return "A Personal Improvement Plan is being drawn up"; },
      terrible: function(d) { return "Dismissal papers are being signed"; },
      zero: function(d) { return "Fired"; }
    },
    burnout: {
      perfect: function(d) { return "Perfect"; },
      good: function(d) { return "Lightly annoyed at work"; },
      fine: function(d) { return "Hard to get up in the morning"; },
      bad: function(d) { return "Sick of working"; },
      terrible: function(d) { return "Almost burned out"; },
      zero: function(d) { return "Burned out"; }
    },
    label: {
      health: function(d) { return "Physical health"; },
      burnout: function(d) { return "Mental health"; },
      company: function(d) { return "Status in the company"; },
      performance: function(d) { return "Performance"; }
    }
  },
  chat: {
    action: {
      message_teammate: function(d) { return "Respond to " + x(d.name, "en", (" dative").trim()); }
    },
    greetings: {
      v1: function(d) { return "Hi there"; },
      v2: function(d) { return "Hey"; },
      v3: function(d) { return "Hi"; },
      v4: function(d) { return "Hello"; },
      v5: function(d) { return "Hey! What's up?"; }
    },
    thanks: {
      v1: function(d) { return "Thanks"; },
      v2: function(d) { return "Thank you!"; },
      v3: function(d) { return "Thanks so much!"; }
    },
    figured_out: {
      v1: function(d) { return "Got it"; },
      v2: function(d) { return "All right, I figured it out. Thanks"; },
      v3: function(d) { return "Nvm, I figured it out myself"; },
      v4: function(d) { return "Ah, I figured out the problem. Don't mind me!"; },
      v5: function(d) { return "I figured it out by myself, I'll let you know if anything else comes up"; }
    },
    help: {
      v1: function(d) { return "I'm having issues with " + d.task_name + ". Can you help?"; },
      v2: function(d) { return "I'm having trouble figuring out " + d.task_name + " " + plural(d.sadness, 0, en, { one: "", "0": ":(", "1": ":((", other: ":(((" }) + " \n\nCan you please help?"; },
      v3: function(d) { return "Hey, I'm stuck on " + d.task_name + ". Help, please?"; },
      v4: function(d) { return "Hey! " + d.task_name + " is too much for me, can you help?"; },
      v5: function(d) { return "Can I call you to talk about " + d.task_name + "? I can't figure out one problem"; }
    }
  },
  teammate: {
    name_nom_gen_dat: {
      v1: function(d) { return "Jack|Jack|Jack"; },
      v2: function(d) { return "John|John|John"; },
      v3: function(d) { return "Alex|Alex|Alex"; },
      v4: function(d) { return "Paul|Paul|Paul"; },
      v5: function(d) { return "Peter|Peter|Peter"; }
    }
  },
  candidate: {
    first_names: function(d) { return "Christopher|Sophia|Jacob|Emma|Tom|George|Emily|James|Will|Noah|Liam|Abby|Ethan|Michael|Alex|Daniel|Joe|Elizabeth|Robert|Aria|Henry|Leo|Edward|Max|Sam|Chloe"; },
    last_names: function(d) { return "Smith|Jones|Williams|Brown|Taylor|Foster|Wilson|Evans|Thomas|Roberts|Johnson|Miller|Davis|Murphy|Martin|Roy|Lee|White|Bell|Burton|Stevens|Lewis|Walker|Payne|Baker|Holmes|Owen"; }
  },
  app: {
    browser: {
      name: function(d) { return "Iron Browser"; }
    },
    chat: {
      name: function(d) { return "Sling Messenger"; }
    },
    calendar: {
      name: function(d) { return "Calendar"; }
    },
    editor: {
      name: function(d) { return "Code Junkie"; }
    },
    wrike: {
      name: function(d) { return "Wrike"; }
    },
    computer: {
      name: function(d) { return "PearNote"; }
    }
  },
  website: {
    blog: function(d) { return "DevTech"; },
    videos: function(d) { return "YouCube"; }
  },
  feedback_note: function(d) { return "Got feedback or improvement ideas? Email us at techclub@team.wrike.com"; }
}